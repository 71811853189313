<template>
    <div class="wrap">
        <div class="top-wrap ">
            <searchInput />
            <div class="tab-wrap delete-bottom " v-if="tablist && tablist.length">
                <el-tabs v-model="activeTab" @tab-click="changeTab"
                         style="margin: 0 auto; max-width:1100px;"
                >
                    <el-tab-pane label="精选" name="0" />
                    <el-tab-pane v-for="item in tablist" :label="item.title" :name="`${item.id}`"
                                 :key="item.id"
                    />
                </el-tabs>
            </div>
        </div>
        <div class="alert">
            <el-alert title="拼团活动仅限移动端参与" type="warning"
                      v-if="activeTab === &quot;22222&quot;"
                      show-icon
            />
        </div>

        <sortFilter @sort-change="sortChange" @biz-change="bizChange" ref="sf" :bizType="bizType" />

        <div class="goods-list-wrap " v-if="goodsList && goodsList.length>0">
            <goods-model
                v-for="item in goodsList"
                :key="item.goodsId"
                :item="item"
                :is-group="activeTab"
            />
            <div class="wrap" v-for="n in 20" :key="n" />
        </div>
        <div class="goods-list-wrap  loading"
             v-else-if="(!goodsList || goodsList.length===0) && loading"
             element-loading-text="拼命加载中"
             v-loading="loading"
        />
        <div class="goods-list-wrap " v-else-if="(!goodsList || goodsList.length===0) && !loading ">
            <noData><p>抱歉，暂时找不到商品，稍后再来</p></noData>
        </div>
        <div class="goods-list-wrap " v-else>
            <errorData>
                <div>
                    <p>数据加载失败</p>
                    <p @click="reload" class="reload">重新加载</p>
                </div>
            </errorData>
        </div>

        <div class="pagination" v-if="goodsList && goodsList.length>0">
            <pagination :total="maxRowCount"
                        :page-size="pageSize"
                        :current-page="pageIndex"
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
            />
        </div>

        <div class="unfold-wrap">
            <unfold-layer />
        </div>
        <goods-dialog />
        <!-- <div class="sign" v-if="showSign">
            <el-dialog
                title="重要通知"
                :visible.sync="signDialogVisible"
                :show-close="closeSign"
                :close-on-click-modal="closeSign"
                width="50%"
                center
                style="font-weight: 700"
            >
                <div class="content">
                    <div class="title">亲爱的赚客:</div>
                    <div class="text">
                        <div v-if="enterToSign == 1" v-html="enterNote">{{ enterNote }}</div>
                        <div v-if="enforceToSign == 1" v-html="enforceNote">{{ enforceNote }}</div>
                    </div>
                    <div class="zhuankeImg">
                        <img src="https://kaola-haitao.oss.kaolacdn.com/ba5af72b-ee33-46a5-84f0-2652eaf31570_430_430.jpg" alt="">
                    </div>
                    <div class="remark">微信扫描登陆考拉赚客公众号</div>
                </div>
            </el-dialog>
        </div> -->
        <div v-if="showNewBrand">
            <div class="mask" />
            <div class="newBrand">
                <div class="mm">感谢您对考拉赚客的信任！</div>
                <div>我们更新了赚客 <span @click="toAgg" style="color: red">《营销服务协议》</span>，，来为您提供更优质的服务和更丰富的权益，请及时阅读知晓。如您选择“暂不接受 点击退出”，但主动推广手机天猫商品，则视为您接受更新后的协议。</div>
                <div class="btn-wrapper">
                    <div class="agg" @click="agree">我已知晓，继续赚钱</div>
                    <div @click="back">暂不接受 点击退出</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import goodsModel from '../../components/goodsPromotion/goodsModel.vue';
import unfoldLayer from '../../components/goodsPromotion/unfoldLayer.vue';
import goodsDialog from '../../components/goodsPromotion/goodsDialog.vue';
import pagination from '../../components/common/pagination.vue';
import searchInput from '../../components/search/search-input.vue';
import noData from '../../components/common/noData.vue';
import errorData from '../../components/common/errorData.vue';
import sortFilter from '../../components/search/sort-filter';
import logout from '../../utils/logout';

export default {
    name: 'goodsPromotion',
    components: {
        goodsModel,
        unfoldLayer,
        goodsDialog,
        pagination,
        noData,
        errorData,
        searchInput,
        sortFilter
    },
    data() {
        return {
            showNewBrand: false,
            activeTab: '0',
            maxRowCount: 300,
            pageSize: 40,
            pageIndex: 1,
            tablist: [],
            goodsList: [],
            selectedList: [],
            checkFlag: false,
            isActive: false,
            loading: true,
            orderby: 1,
            signDialogVisible: true,
            settleSignStatus: 2,
            bizType: 0
        };
    },
    computed: {
        ...mapState([
            'enterToSign',
            'enterNote',
            'zhuankeType',
            'enforceToSign',
            'enforceNote'
        ]),
        showSign() {
            // eslint-disable-next-line max-len
            return this.settleSignStatus !== 2 && this.settleSignStatus !== 3 && (`${this.enterToSign}` === '1' || `${this.enforceToSign}` === '1') && this.zhuankeType === 0;
        },
        closeSign() {
            return `${this.enterToSign}` === '1';
        }
    },
    async created() {
        await this.getCorpZhuankeTabList();
        await this.getCorpZhuankeCollectionGoodsList(true);
        await this.$store.dispatch('getSignDisconf');
        await this.getPersonalUserSettleSignStatus();
        await this.getCorpZhuankeGoodsTotalPage();
    },

    methods: {
        toAgg() {
            window.location.href = 'https://pub.kaola.com/help?key=zhuanke_help_Marketing%20agreement';
        },
        back() {
            logout();
        },
        agree() {
            this.$request({
                url: '/api/user/signWithFC'
            });
            this.showNewBrand = false;
        },
        // 获取总共的页数
        async getCorpZhuankeGoodsTotalPage() {
            const data = await this.$request({
                url: '/api/goodsPromotion/getCorpZhuankeGoodsTotalPage',
                params: {
                    pageSize: this.pageSize,
                    tabId: this.activeTab,
                    orderby: this.orderby,
                    bizType: this.bizType
                },
                toast: false
            });
            this.maxRowCount = data;
        },
        //获取tab列表
        async getCorpZhuankeTabList() {
            const data = await this.$request({
                url: '/api/goodsPromotion/getCorpZhuankeTabList'
            });
            this.tablist = data || [];
        },
        //获取精选列表
        async getCorpZhuankeCollectionGoodsList(isInit = false) {
            try {
                this.goodsList = [];
                this.loading = true;
                let params = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    orderby: this.orderby,
                    bizType: this.bizType
                };

                // if(this.bizType !== -1) {
                //     params.bizType = this.bizType
                // }

                let data = await this.$request({
                    url: '/api/goodsPromotion/getCorpZhuankeCollectionGoodsList',
                    params
                });

                if(isInit && (!data.goodsList || !data.goodsList.length)) {
                    params.bizType = this.bizType = 0;
                    data = await this.$request({
                        url: '/api/goodsPromotion/getCorpZhuankeCollectionGoodsList',
                        params
                    });
                }

                this.goodsList = data.goodsList;
            } finally {
                this.loading = false;
            }

        },
        //获取商品列表
        async getCorpZhuankeGoodsList() {
            try {
                this.goodsList = [];
                this.loading = true;
                const params = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    tabId: this.activeTab,
                    orderby: this.orderby,
                    bizType: this.bizType

                };

                // if(this.bizType !== -1) {
                //     params.bizType = this.bizType
                // }

                const data = await this.$request({
                    url: '/api/goodsPromotion/getCorpZhuankeGoodsList',
                    params
                });
                this.goodsList = data.goodsList;
            }finally {
                this.loading = false;
            }
        },

        addData(list) {
            if(this.activeTab === '22222') {
                list.forEach((value) => {
                    Object.assign(value, {groupBy: true});
                });
            }
        },

        sortChange(e) {
            this.orderby = e;
            this.pageIndex = 1;
            this.getCorpZhuankeGoodsTotalPage();
            this.handleClick();
        },
        bizChange(e) {
            this.bizType = e;
            this.getCorpZhuankeGoodsTotalPage();
            this.pageIndex = 1;
            this.handleClick();
        },
        async handleClick() {
            if(this.activeTab === '0') {
                await this.getCorpZhuankeCollectionGoodsList();
            } else {
                await this.getCorpZhuankeGoodsList();
            }
        },
        changeTab() {
            this.getCorpZhuankeGoodsTotalPage();
            this.pageIndex = 1;
            this.bizType = 0;
            this.handleClick();
        },
        handleCurrentChange(e) {
            this.pageIndex = e;
            this.handleClick();
        },
        handleSizeChange(e) {
            this.pageIndex = 1;
            this.pageSize = e;
            this.getCorpZhuankeGoodsTotalPage();
            this.handleClick();
        },
        showCheck(e) {
            e.target.childNodes[0].style.display = 'block';
        },
        hideCheck() {
            if(this.haveSelection < 1) {
                this.clearPattern();
            }
        },
        clearPattern() {
            const arr = this.$refs.checkbox;
            arr.forEach((value) => {
                value.style.display = 'none';
            });
        },
        showPattern() {
            const arr = this.$refs.checkbox;
            arr.forEach((value) => {
                value.style.display = 'block';
            });
        },
        reload() {
            this.handleClick();
        },
        async getPersonalUserSettleSignStatus() {
            const { signInfo, settleSignStatus } = await this.$request({
                url: '/api/user/settleSignStatus',
                toast: false
            });

            this.settleSignStatus = settleSignStatus;
            this.fcSignStatus = (signInfo || {}).fcSignStatus;

            if(this.fcSignStatus !== 1) {
                this.showNewBrand = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.wrap {
    .top-wrap {
        background: #fff;

        .tab-wrap {
            //max-width: 1100px;
            padding:0 120px;
            display: flex;
            justify-content: center;

        }
    }

    .goods-list-wrap {
        margin-right: -16px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        justify-content: space-between;
        .reload {
            cursor: pointer;
            color: #ff1e32;
            text-decoration: underline;
        }
        .wrap {
            margin-right: 16px;
            width: 220px;
        }
    }
    .alert {
        margin-top: 10px;
    }
    .loading {
        margin-top: 200px;
        // background: #f5f5f5;
    }
    .pagination {
        text-align: center;
        margin-top: 20px;
        padding-bottom: 60px;
    }
    .fold-wrap {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;

    }
    .unfold-wrap {
        position: fixed;
        top: 0;
        left: 200px;
        bottom: 0;

        background: rgba(0,0,0,.8);
        z-index: 999;

    }
    .sign {
        .title {
            //font-size: 18px;
            //font-weight: 700;
            margin-bottom: 20px;
            font-weight: 500;
        }
        .text {
            max-height: 140px;
            overflow-y: scroll;
            font-weight: 500;
        }
        .zhuankeImg {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            margin-top: 10px;
            img {
                width: 100%;
            }
        }
        .remark {
            text-align: center;
            margin-top: 10px;
            font-weight: 500;
        }
    }

}

.newBrand {
    width: 420px;
    height: 515px;
    background-image: url(https://img.alicdn.com/imgextra/i2/O1CN013PVW0d1c5wmGkqEdI_!!6000000003550-2-tps-421-516.png);
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0 25px;

    .mm {
        margin-top: 335px;
    }

    .btn-wrapper {
        display: flex;
        width: 324px;
        margin-left: 25px;
        justify-content: space-between;
        margin-top: 4px;
        div {
            width: 150px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            color: #333;
            font-size: 15px;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            cursor: pointer;
        }

        .agg {
            color: #fff;
            background-color: #f00;
            border: none;
        }

    }
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
}

</style>

<style lang='scss'>
.delete-bottom {
    .el-tabs__nav-wrap:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0px;
        background-color: #FFF;
        z-index: 1;
    }
    .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 15px 5px 0px 5px;
    }
}
</style>
